<template>
  <div>
    <v-card elevation="0">
      <v-card-title>{{ $t("Portal") }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2" class="font-weight-bold">{{ $t("Name") }}</v-col>
          <v-col cols="4" class="font-weight-bold">{{ $t("Last run") }}</v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row v-for="(item, index) in items" :key="index">
          <v-col cols="2">{{ item.name }}</v-col>
          <v-col cols="4">{{ item.latesRun }}</v-col>
          <v-col cols="4">
            <v-btn
              >{{ $t("Synchonise") }}
              <v-icon right > mdi-cloud-sync </v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="0">
      <v-card-title> B2C </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2" class="font-weight-bold">{{ $t("Name") }}</v-col>
          <v-col cols="4" class="font-weight-bold">{{ $t("Last run") }}</v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row v-for="(item2, index) in items2" :key="index">
          <v-col cols="2">{{ item2.name }}</v-col>
          <v-col cols="4">{{ item2.latesRun }}</v-col>
          <v-col cols="4">
            <v-btn
              >{{ $t("Synchonise") }}
              <v-icon right > mdi-cloud-sync </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import helpers from "../../helpers";
export default {
  data() {
    return {
      items: [
        {
          name: "Artikels",
          latesRun: helpers.changeTimeToReadeble(new Date()),
        },
        { name: "Prijzen", latesRun: helpers.changeTimeToReadeble(new Date()) },
        { name: "Klanten", latesRun: helpers.changeTimeToReadeble(new Date()) },
        {
          name: "Adresses",
          latesRun: helpers.changeTimeToReadeble(new Date()),
        },
        { name: "Stock", latesRun: helpers.changeTimeToReadeble(new Date()) },
        { name: "Orders", latesRun: helpers.changeTimeToReadeble(new Date()) },
        {
          name: "Orderstatussen",
          latesRun: helpers.changeTimeToReadeble(new Date()),
        },
      ],
      items2: [
        {
          name: "Artikels",
          latesRun: helpers.changeTimeToReadeble(new Date()),
        },
        {
          name: "Prijzen",
          latesRun: helpers.changeTimeToReadeble(new Date()),
        },
        { name: "Stock", latesRun: helpers.changeTimeToReadeble(new Date()) },
      ],
    };
  },
};
</script>

<style>
</style>